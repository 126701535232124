// eslint-disable-next-line import/no-cycle
import {
  Actions,
  CurrentReportTransplantCentreEditAction,
  CurrentReportTransplantCentreErrorAction,
  CurrentReportTransplantCentreRequestAction,
  CurrentReportTransplantCentreSuccessAction,
  SaveNewReportSuccessAction,
  SingleSavedReportSuccessAction,
} from '../actions';

type UserActions =
  | CurrentReportTransplantCentreEditAction
  | CurrentReportTransplantCentreErrorAction
  | CurrentReportTransplantCentreRequestAction
  | CurrentReportTransplantCentreSuccessAction
  | SaveNewReportSuccessAction
  | SingleSavedReportSuccessAction;

export type TransplantCentreReducerState = {
  address: string[];
  hasErrored: boolean;
  isFetching: boolean;
  message?: string;
};

const initialState: TransplantCentreReducerState = {
  address: [],
  hasErrored: false,
  isFetching: false,
};

const transplantCentre = (
  state: TransplantCentreReducerState = initialState,
  action: UserActions
): TransplantCentreReducerState => {
  switch (action.type) {
    case Actions.CURRENT_REPORT_TRANSPLANT_CENTRE_SUCCESS:
      return {
        address: action.payload.data.TransplantCentreAddress,
        hasErrored: false,
        isFetching: false,
      };
    case Actions.CURRENT_REPORT_TRANSPLANT_CENTRE_EDIT:
      return {
        address: action.payload,
        hasErrored: false,
        isFetching: false,
      };

    case Actions.CURRENT_REPORT_TRANSPLANT_CENTRE_ERROR:
      if (action.payload.response.status === 404) {
        return {
          ...state,
          address: [],
          hasErrored: false,
          message: `Transplant Centre not found for patient ${action.payload.params.patientId}`,
          isFetching: false,
        };
      }
      return {
        ...state,
        address: [],
        hasErrored: true,
        message: `Error while fetching Transplant Centre Details - Status: ${action.payload.response.status}`,
        isFetching: false,
      };
    case Actions.CURRENT_REPORT_TRANSPLANT_CENTRE_REQUEST:
      return {
        ...state,
        address: [],
        isFetching: true,
        hasErrored: false,
      };
    case Actions.SINGLE_SAVED_REPORT_SUCCESS:
    case Actions.SAVE_NEW_REPORT_SUCCESS:
      return {
        ...state,
        address: action.payload.data.entities.SearchReports[action.payload.data.result].TransplantCentre,
      };
    default:
      return state;
  }
};

export default transplantCentre;
