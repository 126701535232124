import moment from 'moment';
import donorTypes from '../../../core/constants/donorTypes';
import type { ReduxState } from '../../../rootReducer';
import type { ApiReportSearchSummary, ApiSavedReport } from '../../types/api';
import type { DonorType } from '../../../core/types';

const getSavedReports = (state: ReduxState) => state.searchReport.savedReports;

const getSavedReportsDetails = (state: ReduxState): { [string: string]: ApiSavedReport } =>
  getSavedReports(state).reports;

const getSingleSavedReport = (state: ReduxState, id: string): ApiSavedReport => getSavedReportsDetails(state)[id];

const getSavedReportsSearchSummary = (state: ReduxState, id: string): ApiReportSearchSummary =>
  getSavedReportsDetails(state)[id].SearchSummary;

const totalMatchTypeSelectedDonors = (
  searchSummary: ApiReportSearchSummary,
  matchType: keyof ApiReportSearchSummary
): number =>
  Object.values<number>(searchSummary[matchType]).reduce(
    // $FlowExpectedError - Flow doesn't cope well with `Object.values`
    (total: number, current: number) => total + current,
    0
  );

const getReportsTotalNumberOfSavedDonors = (state: ReduxState, id: string) => {
  const searchSummary = getSavedReportsSearchSummary(state, id);
  return {
    AB: totalMatchTypeSelectedDonors(searchSummary, 'AB'),
    ABDR: totalMatchTypeSelectedDonors(searchSummary, 'ABDR'),
  };
};

const getReportsAwaitingAuthIds = (state: ReduxState): string[] => getSavedReports(state).awaitingAuthorisation;

const reportsAwaitingAuthCount = (state: ReduxState): number => getReportsAwaitingAuthIds(state).length;

const getAllAwaitingAuthReports = (state: ReduxState): ApiSavedReport[] => {
  const reportsAwaitingAuthIds = getReportsAwaitingAuthIds(state);
  return reportsAwaitingAuthIds.map((id) => getSingleSavedReport(state, id));
};

const isFetchingReports = (state: ReduxState): boolean => getSavedReports(state).isFetching;

const hasFetchReportsErrored = (state: ReduxState): boolean => getSavedReports(state).hasErrored;

const getReportErrorMessage = (state: ReduxState): string[] => {
  const error = getSavedReports(state).message;
  return error ? [error] : [];
};

const getReportIsMinimal = (state: ReduxState): boolean => state.searchReport.savedReports.minimal;

const getSearchReportsByIds = (state: ReduxState, reportIds: string[]): ApiSavedReport[] =>
  reportIds.map((id) => getSingleSavedReport(state, id));

const getUsersWithReportsList = (state: ReduxState): string[] => {
  // TODO: Add a function that also takes the reports authorisor
  const savedReports = getSavedReportsDetails(state);
  const userList = Object.values(savedReports).reduce(
    // $FlowExpectedError - Flow doesn't cope well with `Object.values`
    (users: string[], report: ApiSavedReport) => {
      if (users.includes(report.Author)) {
        return users;
      }
      users.push(report.Author);
      return users;
    },
    []
  );
  return userList;
};

const getSavedReportsLastFifteenDays = (state: ReduxState): ApiSavedReport[] => {
  const reports = getSavedReportsDetails(state);
  // $FlowExpectedError - Flow doesn't cope well with `Object.values`
  return Object.values(reports).filter((report: ApiSavedReport) =>
    moment(report.DateGenerated).isAfter(moment().subtract(15, 'days'))
  );
};

const getReportsForSingleUser = (state: ReduxState, user: string): ApiSavedReport[] => {
  const reports = getSavedReportsLastFifteenDays(state);
  return Object.values(reports).filter(
    // $FlowExpectedError - Flow doesn't cope well with `Object.values`
    (report: ApiSavedReport) => report.Author === user || report.Authoriser === user
  );
};

const getSelectedResultSetIdsForSingleReport = (
  state: ReduxState,
  donorType: DonorType,
  reportId?: string
): string[] => {
  if (reportId) {
    const savedReport = getSingleSavedReport(state, reportId);
    return donorType === donorTypes.adult.value ? savedReport.SelectedDonorSetsIds : savedReport.SelectedCordSetsIds;
  }
  return [];
};

export default {
  getSavedReportsSearchSummary,
  getReportsTotalNumberOfSavedDonors,
  reportsAwaitingAuthCount,
  getAllAwaitingAuthReports,
  hasFetchReportsErrored,
  getReportErrorMessage,
  getSingleSavedReport,
  getSavedReports,
  getSearchReportsByIds,
  isFetchingReports,
  getUsersWithReportsList,
  getSavedReportsDetails,
  getReportsForSingleUser,
  getReportIsMinimal,
  getSelectedResultSetIdsForSingleReport,
  getSavedReportsLastFifteenDays,
};
