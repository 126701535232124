import _ from 'lodash';
import { Response as ApiResponse } from '@an/nova-frontend-rest-client';
// eslint-disable-next-line import/no-cycle
import SavedReportsSelectors from '../../savedReports/redux/selectors';
// eslint-disable-next-line import/no-cycle
import DashboardSelectors from './selectors';
// eslint-disable-next-line import/no-cycle
import DashboardHelpers from './dashboardHelpers';
import type { Dispatch, GetState } from '../../../core/types';
import type { ReportIdsByStatus } from '../../types';
import type { ApiReportIdsByStatus } from '../../types/api';
import type { NormalizedSavedReportList } from '../../savedReports/redux/actions';
// eslint-disable-next-line import/no-cycle
import { getSavedReports, getMinimalSavedReports } from '../../savedReports/redux/actions';

const REPORT_DASHBOARD_REPORT_STATUS_IDS_UPDATE: 'searchReports/dashboard/statusIds/update' =
  'searchReports/dashboard/statusIds/update';
const REPORT_DASHBOARD_SELECTED_USER_UPDATE: 'searchReports/dashboard/selectedUser/update' =
  'searchReports/dashboard/selectedUser/update';

export const Actions = {
  REPORT_DASHBOARD_REPORT_STATUS_IDS_UPDATE,
  REPORT_DASHBOARD_SELECTED_USER_UPDATE,
};

type UpdateReportStatusIdsFromCamelCaseAction = {
  type: typeof REPORT_DASHBOARD_REPORT_STATUS_IDS_UPDATE;
  payload: ReportIdsByStatus;
};
type UpdateSelectedReportUserAction = {
  type: typeof REPORT_DASHBOARD_SELECTED_USER_UPDATE;
  payload: string;
};

export type ReportDashboardAction = UpdateReportStatusIdsFromCamelCaseAction | UpdateSelectedReportUserAction;

const updateReportStatusIdsFromCamelCase = (
  reportIdsByStatus: ReportIdsByStatus
): UpdateReportStatusIdsFromCamelCaseAction => ({
  type: Actions.REPORT_DASHBOARD_REPORT_STATUS_IDS_UPDATE,
  payload: reportIdsByStatus,
});

const updateSelectedReportUser = (user: string): UpdateSelectedReportUserAction => ({
  type: Actions.REPORT_DASHBOARD_SELECTED_USER_UPDATE,
  payload: user,
});

const updateReportStatusIds = (reportStatusIds: ApiReportIdsByStatus) => (dispatch: Dispatch<any>) => {
  const reportStatusIdsCamelCase = _.mapKeys(reportStatusIds, (id, key) => _.camelCase(key));
  dispatch(updateReportStatusIdsFromCamelCase(reportStatusIdsCamelCase as ReportIdsByStatus));
};

const getSearchReports = (reports: ApiResponse<NormalizedSavedReportList>) => (dispatch: Dispatch<any>) => {
  const { body } = reports.response;
  const reportStatusIds = DashboardHelpers.getReportIdsByStatus(
    body ? body.result.SearchReports.map((id: string) => body && body.entities.SearchReports[id]) : []
  );
  dispatch(updateReportStatusIds(reportStatusIds));
};

export const fetchAllReportsForDashboard = () => async (dispatch: Dispatch<any>) => {
  const reports = await dispatch(getSavedReports());
  dispatch(getSearchReports(reports));
};

export const fetchMinimalReportsForDashboard = () => async (dispatch: Dispatch<any>) => {
  const reports = await dispatch(getMinimalSavedReports());
  dispatch(getSearchReports(reports));
};

export const filterReportsOnDashboardByUser = (user: string) => (dispatch: Dispatch<any>, getState: GetState) => {
  dispatch(updateSelectedReportUser(user));

  const visibleReportDetails = user
    ? SavedReportsSelectors.getReportsForSingleUser(getState(), user)
    : SavedReportsSelectors.getSavedReportsLastFifteenDays(getState());

  const savedReportsStatusIds = DashboardHelpers.getReportIdsByStatus(visibleReportDetails);
  dispatch(updateReportStatusIds(savedReportsStatusIds));
};

const FilterOut = (ids: any, payload: any) => _.filter(ids, (id) => payload !== id);

export const removeReportFromDashboard = (reportId: string) => (dispatch: Dispatch<any>, getState: GetState) => {
  const reportIdsByStatus = DashboardSelectors.getReportIds(getState());
  const filteredIdsByStatus = _.mapValues(reportIdsByStatus, (ids) => FilterOut(ids, reportId));
  dispatch(updateReportStatusIdsFromCamelCase(filteredIdsByStatus));
};
