import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import donorTypes from '../../../core/constants/donorTypes';
import type { DonorType } from '../../../core/types';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import * as patientActions from '../../../patient/patientDashboard/redux/actions';
import PatientSelectors from '../../../patient/patientDashboard/redux/selectors';
import type { ReduxState } from '../../../rootReducer';
import { SavedReportsSelectors } from '../../../searchReports/index';
import * as actions from '../../redux/actions';
import Selectors from '../../redux/selectors';
import { styles } from '../../style';
import SavedDonorsSummary from './SavedDonorsSummary';

type OwnProps = {
  algorithm?: string;
  donorType: DonorType;
  patientId: string;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  reportId?: string; // used in `mapStateToProps`
};
type StateProps = {
  fetchingSavedResults: boolean;
  reportsSelectedResultSetIds: string[];
  savedResultSets: SavedResultSet[];
  repeatSearchDate: Moment | null | undefined;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  fetchingSavedResults: Selectors.isFetchingSavedResults(state, ownProps.donorType),
  reportsSelectedResultSetIds: SavedReportsSelectors.getSelectedResultSetIdsForSingleReport(
    state,
    ownProps.donorType,
    ownProps.reportId
  ),
  savedResultSets: Selectors.getSavedResultSets(state, ownProps.donorType),
  repeatSearchDate: PatientSelectors.getRepeatSearchDate(state, ownProps.patientId),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  showSavedResultSet: bindActionCreators(actions.showSavedResultSet, dispatch),
  fetchRepeatSearchDate: bindActionCreators(patientActions.fetchRepeatSearchDate, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SavedDonorsList extends PureComponent<Props> {
  static defaultProps = {
    algorithm: undefined,
  };

  componentDidMount() {
    const { patientId, fetchRepeatSearchDate } = this.props;
    fetchRepeatSearchDate(patientId);
  }

  render() {
    const { algorithm, donorType, fetchingSavedResults, savedResultSets } = this.props;
    if (fetchingSavedResults) {
      return (
        <div>
          <h4>Loading...</h4>
        </div>
      );
    }
    if (savedResultSets.length > 0) {
      const savedSelections = savedResultSets.filter((resultSet) => resultSet.algorithmUsed === algorithm);

      const orderedDonorSelection = _.orderBy(savedSelections, 'dateModified', 'desc');

      return (
        <div style={{ width: '400px', maxHeight: '400px' }}>
          <ul style={styles.savedDonorsList}>
            {orderedDonorSelection.map((selection) => (
              <li key={selection.id}>{this.RenderSummary(selection)}</li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <div>
        <h4>No Previously Saved {donorType === donorTypes.adult.value ? 'Donors' : 'Cords'}</h4>
      </div>
    );
  }

  isOldSavedDonorSet = (date: string | undefined): boolean | null => {
    const { repeatSearchDate } = this.props;

    if (!repeatSearchDate) {
      return null;
    }

    const savedSetDate = moment(date);
    const previousMonth = moment().subtract(1, 'months');
    const maxDateLimit = moment.max(moment(repeatSearchDate), previousMonth);
    return savedSetDate.isBefore(maxDateLimit);
  };

  RenderSummary = (selection: SavedResultSet) => {
    const { donorType, reportsSelectedResultSetIds, showSavedResultSet } = this.props;
    const { filterSummary } = selection.appliedFilters;
    const selectionLength = selection.selectedResults.length;
    // @ts-expect-error includes takes as first parameter string, but selection.id === string | undefined
    const isSelectedInReport = reportsSelectedResultSetIds.includes(selection.id);
    const showSavedSet = () => showSavedResultSet(donorType, selection.id);

    return (
      <SavedDonorsSummary
        isOldSavedDonorSet={this.isOldSavedDonorSet(selection.dateModified)}
        isSelectedInReport={isSelectedInReport}
        selectionLength={selectionLength}
        showSavedSet={showSavedSet}
        filterSummary={filterSummary}
        donorType={donorType}
      />
    );
  };
}

export default connector(SavedDonorsList);
