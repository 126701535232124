import React from 'react';
import { useSelector } from 'react-redux';
import Subject from '../../../redux/selectors';
import ExistingInvestigationsTable from './ExistingInvestigationsTable';
import ExistingInvestigationDetails from './ExistingInvestigationDetails';
import ExistingInvestigationInvoicing from './ExistingInvestigationInvoicing';
import { WarningMessage } from '../../../../core/components/WarningMessage';

import type { SubjectInvestigation } from '../../../types';
import { ReduxState } from '../../../../rootReducer';
import { ErrorMessage } from '../../../../core';

type Props = {
  setSelectedInvestigation: (selectedInvestigation: number | null) => void;
  selectedInvestigation: number | null;
  subjectId: string | undefined;
  subjectRecordType: string | undefined;
  selectedInvestigationDetails: SubjectInvestigation | undefined;
};

const ExistingSubjectInvestigations = ({
  setSelectedInvestigation,
  selectedInvestigation,
  subjectId,
  subjectRecordType,
  selectedInvestigationDetails,
}: Props) => {
  const subjectInvestigations = useSelector((state: ReduxState) => Subject.getSubjectInvestigations(state));
  const investigationError = useSelector((state: ReduxState) => Subject.getLoadingInvestigationsError(state));
  return (
    <>
      {subjectInvestigations && subjectInvestigations.length > 0 && (
        <ExistingInvestigationsTable
          investigations={subjectInvestigations}
          onSelectRow={setSelectedInvestigation}
          selectedInvestigation={selectedInvestigation}
          subjectRecordType={subjectRecordType}
        />
      )}
      {investigationError && <ErrorMessage errorMessages={['An error has occurred when loading investigations']} />}
      {!investigationError && subjectInvestigations && subjectInvestigations.length === 0 && (
        <WarningMessage message={`No investigations found for ${subjectRecordType || ''} ${subjectId || ''}`} />
      )}
      {selectedInvestigationDetails && (
        <>
          <ExistingInvestigationDetails investigation={selectedInvestigationDetails} />
          <ExistingInvestigationInvoicing investigation={selectedInvestigationDetails} />
        </>
      )}
    </>
  );
};
export default ExistingSubjectInvestigations;
