import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { ReportStatuses } from '../../../core/constants';
// eslint-disable-next-line import/no-cycle
import {
  formatCountries,
  formatCustomCountries,
  formatSavedSetCountries,
} from '../../../core/helpers/apiDataConverter';
import type { Country, CustomCountry, ReportStatus, SavedSetCountry } from '../../../types';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  CurrentReportDetailsResetAction,
  CurrentReportGeneratePdfErrorAction,
  CurrentReportGeneratePdfRequestAction,
  CurrentReportGeneratePdfSuccessAction,
  CurrentReportSetReportOnGoingAction,
  CurrentReportStatusUpdateSuccessAction,
  CurrentReportUpdatePrivateNotesAction,
  CurrentReportUpdateReceivedDateAction,
  CurrentReportUpdateReportIdAction,
  CurrentReportUpdateReportTypeAction,
  CurrentReportUpdateSelectedCordsAction,
  CurrentReportUpdateSelectedCountryNamesAction,
  CurrentReportUpdateSelectedDonorSetAction,
  CurrentReportUpdateSelectedRecommendationsAction,
  ReportedCountriesSuccessAction,
  SaveNewReportSuccessAction,
  SingleSavedReportSuccessAction,
  TogglePendingCheckboxAction,
  UpdateCustomCountriesAction,
  UpdateHiddenStateAction,
  UpdateSearchedCountriesAction,
  CurrentReportDownloadPdfRequestAction,
  CurrentReportDownloadPdfSuccessAction,
  CurrentReportDownloadPdfFinalAttemptErrorAction,
  CurrentReportDownloadPdfFinalAttemptSuccessAction,
} from '../actions';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UserActions =
  | CurrentReportDetailsResetAction
  | CurrentReportGeneratePdfErrorAction
  | CurrentReportGeneratePdfRequestAction
  | CurrentReportGeneratePdfSuccessAction
  | CurrentReportSetReportOnGoingAction
  | CurrentReportStatusUpdateSuccessAction
  | CurrentReportUpdatePrivateNotesAction
  | CurrentReportUpdateReceivedDateAction
  | CurrentReportUpdateReportIdAction
  | CurrentReportUpdateReportTypeAction
  | CurrentReportUpdateSelectedCordsAction
  | CurrentReportUpdateSelectedCountryNamesAction
  | CurrentReportUpdateSelectedDonorSetAction
  | CurrentReportUpdateSelectedRecommendationsAction
  | LocationChangeAction
  | ReportedCountriesSuccessAction
  | SaveNewReportSuccessAction
  | SingleSavedReportSuccessAction
  | TogglePendingCheckboxAction
  | UpdateCustomCountriesAction
  | UpdateHiddenStateAction
  | UpdateSearchedCountriesAction
  | CurrentReportDownloadPdfRequestAction
  | CurrentReportDownloadPdfSuccessAction
  | CurrentReportDownloadPdfFinalAttemptSuccessAction
  | CurrentReportDownloadPdfFinalAttemptErrorAction;

export type ReportDetailsReducerState = {
  additionalNotes: string;
  customCountries: CustomCountry[];
  id: string;
  isCreatingPdf: boolean;
  isDownloadingPdf: boolean;
  isInternational?: boolean;
  locationChanged: boolean;
  savedSetCountries: SavedSetCountry[];
  receivedDate?: Date;
  reportOnGoing: boolean;
  reportPdfFileName?: string | undefined;
  reportStatus: ReportStatus;
  reportType?: string;
  searchedCountries: Partial<Country>[];
  selectedCordSets: string[];
  selectedCountryNames: (string | undefined)[];
  selectedDonorSets: string[];
  selectedRecommendations: string[];
};

const initialState: ReportDetailsReducerState = {
  // TODO-Flow: Make undefined by default
  additionalNotes: '',
  customCountries: [],
  // TODO-Flow: Make undefined by default
  id: '',
  isInternational: undefined,
  isCreatingPdf: false,
  isDownloadingPdf: false,
  locationChanged: true,
  savedSetCountries: [],
  receivedDate: undefined,
  reportOnGoing: false,
  // TODO-Flow: Make undefined by default
  reportType: '',
  reportStatus: ReportStatuses.Draft,
  searchedCountries: [],
  selectedCordSets: [],
  selectedCountryNames: [],
  selectedDonorSets: [],
  selectedRecommendations: [],
};

const details = (state: ReportDetailsReducerState = initialState, action: UserActions): ReportDetailsReducerState => {
  let indexOfCountry: number;
  let updatedCountry: Record<string, unknown>;
  let countries: Partial<Country>[];
  switch (action.type) {
    case Actions.CURRENT_REPORT_DETAILS_RESET:
      return {
        ...initialState,
      };
    case Actions.CURRENT_REPORT_SET_REPORT_ON_GOING:
      return {
        ...state,
        reportOnGoing: true,
        locationChanged: false,
      };
    case LOCATION_CHANGE:
      if (state.locationChanged) {
        return {
          ...state,
          reportOnGoing: false,
        };
      }
      return {
        ...state,
        locationChanged: true,
      };
    case Actions.CURRENT_REPORT_UPDATE_REPORT_TYPE:
      return {
        ...state,
        reportType: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS:
      return {
        ...state,
        selectedDonorSets: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS:
      return {
        ...state,
        selectedCordSets: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_PRIVATE_NOTES:
      return {
        ...state,
        additionalNotes: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_RECEIVED_DATE:
      return {
        ...state,
        receivedDate: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS:
      return {
        ...state,
        selectedRecommendations: action.payload,
      };
    case Actions.CURRENT_REPORT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        reportStatus: action.payload.params.newStatus,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_REQUEST:
      return {
        ...state,
        reportPdfFileName: undefined,
        isCreatingPdf: true,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_SUCCESS:
      return {
        ...state,
        reportPdfFileName: action.payload.data,
        isCreatingPdf: false,
      };
    case Actions.CURRENT_REPORT_UPDATE_REPORT_ID:
      return {
        ...state,
        reportPdfFileName: undefined,
        isCreatingPdf: true,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_COUNTRY_NAMES:
      return {
        ...state,
        selectedCountryNames: action.payload,
      };
    case Actions.SINGLE_SAVED_REPORT_SUCCESS:
    case Actions.SAVE_NEW_REPORT_SUCCESS: {
      const reportDetails = action.payload.data.entities.SearchReports[action.payload.data.result];
      return {
        ...state,
        id: reportDetails.Id,
        isInternational: reportDetails.IsInternational,
        reportStatus: reportDetails.ReportStatus,
        selectedDonorSets: reportDetails.SelectedDonorSetsIds,
        selectedCordSets: reportDetails.SelectedCordSetsIds,
        additionalNotes: reportDetails.PrivateNotes,
        receivedDate: reportDetails.DateSearchReceived,
        reportPdfFileName: reportDetails.ReportPdfFileName,
        selectedRecommendations: reportDetails.SelectedRecommendations.map((r: string) => r),
        reportType: reportDetails.ReportType,
        searchedCountries: formatCountries(reportDetails.SearchedCountries),
        customCountries: formatCustomCountries(reportDetails.CustomCountries),
      };
    }
    case Actions.TOGGLE_PENDING_CHECKBOX:
      indexOfCountry = state.searchedCountries.findIndex((x) => x.name === action.payload.id);
      updatedCountry = {
        ...state.searchedCountries[indexOfCountry],
        isPending: action.payload.isPending,
      };
      countries = [
        ...state.searchedCountries.slice(0, indexOfCountry),
        updatedCountry,
        ...state.searchedCountries.slice(indexOfCountry + 1),
      ];
      return {
        ...state,
        searchedCountries: countries,
      };
    case Actions.UPDATE_HIDDEN_STATE:
      indexOfCountry = state.searchedCountries.findIndex((x) => x.name === action.payload.id);
      updatedCountry = {
        ...state.searchedCountries[indexOfCountry],
        hidden: action.payload.hidden,
      };
      countries = [
        ...state.searchedCountries.slice(0, indexOfCountry),
        updatedCountry,
        ...state.searchedCountries.slice(indexOfCountry + 1),
      ];
      return {
        ...state,
        searchedCountries: countries,
      };
    case Actions.UPDATE_CUSTOM_COUNTRIES:
      return {
        ...state,
        customCountries: action.payload,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_ERROR:
      return {
        ...state,
        isCreatingPdf: false,
      };
    case Actions.REPORTED_COUNTRIES_SUCCESS:
      return {
        ...state,
        savedSetCountries: formatSavedSetCountries(action.payload.data),
      };
    case Actions.UPDATE_SEARCHED_COUNTRIES:
      return {
        ...state,
        searchedCountries: action.payload,
      };
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_REQUEST: {
      return {
        ...state,
        isDownloadingPdf: true,
      };
    }
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR:
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS:
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS: {
      return {
        ...state,
        isDownloadingPdf: false,
      };
    }

    default:
      return state;
  }
};

export default details;
