import React from 'react';
import TextMessagesTable from './TextMessages/TextMessagesTable';
import { ExternalInvestigationTextMessage } from '../../../../../externalInvestigations/types';

type Props = {
  textMessages: ExternalInvestigationTextMessage[];
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
};

const TextMessagesPopUp = ({ onCancel, textMessages }: Props) => (
  <>
    <h1>Text Messages</h1>
    <TextMessagesTable textMessages={textMessages} />
    <div className="btn-actions">
      <button className="btn btn--secondary btn--inline" type="button" onClick={onCancel}>
        Close
      </button>
    </div>
  </>
);

export default TextMessagesPopUp;
