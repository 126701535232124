import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  PatientSavedReportsErrorAction,
  PatientSavedReportsRequestAction,
  PatientSavedReportsSuccessAction,
  SavedReportMinimalRequestAction,
  SavedReportsAwaitingAuthorisationSuccessAction,
  SavedReportsErrorAction,
  SavedReportsMinimalErrorAction,
  SavedReportsMinimalSuccessAction,
  SavedReportsRequestAction,
  SavedReportsSuccessAction,
  UpdateSavedReportsAction,
} from './actions';
// eslint-disable-next-line import/no-cycle
import { Actions as CurrentReportActions, SingleSavedReportErrorAction } from '../../currentReport/redux/actions';
import type { ApiSavedReport } from '../../types/api';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UserActions =
  | LocationChangeAction
  | PatientSavedReportsErrorAction
  | PatientSavedReportsRequestAction
  | PatientSavedReportsSuccessAction
  | SavedReportMinimalRequestAction
  | SavedReportsAwaitingAuthorisationSuccessAction
  | SavedReportsErrorAction
  | SavedReportsMinimalErrorAction
  | SavedReportsMinimalSuccessAction
  | SavedReportsRequestAction
  | SavedReportsSuccessAction
  | SingleSavedReportErrorAction
  | UpdateSavedReportsAction;

export type SavedReportsReducerState = {
  awaitingAuthorisation: string[];
  hasErrored: boolean;
  isFetching: boolean;
  message?: string;
  // TODO-Flow: Move to entities reducer
  reports: Record<string, ApiSavedReport>;
  minimal: boolean;
  hasStandardRequestFinished: boolean;
};

const initialState: SavedReportsReducerState = {
  awaitingAuthorisation: [],
  hasErrored: false,
  isFetching: false,
  reports: {},
  minimal: false,
  hasStandardRequestFinished: false,
};

const savedReports = (
  state: SavedReportsReducerState = initialState,
  action: UserActions
): SavedReportsReducerState => {
  switch (action.type) {
    case Actions.SAVED_REPORTS_SUCCESS:
    case Actions.PATIENTS_SAVED_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...action.payload.data.entities.SearchReports,
        },
        isFetching: false,
        hasErrored: false,
        minimal: false,
        hasStandardRequestFinished: true,
      };
    case Actions.UPDATE_SAVED_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...action.payload.entities.SearchReports,
        },
        isFetching: false,
        hasErrored: false,
        minimal: false,
        hasStandardRequestFinished: true,
      };
    case Actions.SAVED_REPORTS_MINIMAL_SUCCESS: {
      const reports = !state.hasStandardRequestFinished
        ? {
            ...state.reports,
            ...action.payload.data.entities.SearchReports,
          }
        : state.reports;
      return {
        ...state,
        reports,
        isFetching: false,
        hasErrored: false,
        minimal: !state.hasStandardRequestFinished,
      };
    }
    case Actions.SAVED_REPORTS_REQUEST:
    case Actions.PATIENTS_SAVED_REPORTS_REQUEST:
    case Actions.SAVED_REPORTS_MINIMAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasErrored: false,
        minimal: false,
        hasStandardRequestFinished: false,
      };
    case Actions.SAVED_REPORTS_AWAITING_AUTH_SUCCESS:
      return {
        ...state,
        awaitingAuthorisation: action.payload.data.result.SearchReports,
      };
    case Actions.SAVED_REPORTS_ERROR:
    case Actions.PATIENTS_SAVED_REPORTS_ERROR:
    case Actions.SAVED_REPORTS_MINIMAL_ERROR:
    case CurrentReportActions.SINGLE_SAVED_REPORT_ERROR:
      return {
        ...state,
        hasErrored: true,
        message: `Error while fetching Saved Reports - ${action.payload.response.status}`,
        isFetching: false,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        hasErrored: false,
        message: undefined,
      };
    default:
      return state;
  }
};

export default savedReports;
