import React from 'react';
import { formatDate } from '../../../../../core/helpers/dateHelpers';
import { TextMessage } from '../../../types';
import formatGrid from '../../../../../core/helpers/gridFormatter';
import './textMessageTable.scss';
import { orderTextMessages } from '../../../constants/orderTextMessages';

type Props = {
  textMessages: TextMessage[];
};

const TextMessagesTable = ({ textMessages }: Props) => {
  const orderedTextMessages = orderTextMessages(textMessages);

  const renderGrid = (grid: string | null | undefined) => {
    const formattedGrid = formatGrid(grid);
    return formattedGrid === '' ? '-' : formattedGrid;
  };

  return (
    <div className="table-wrapper">
      <table className="table text-message-table">
        <thead className="table-header table--sticky-header">
          <tr>
            <th>Grid</th>
            <th>Sent Date</th>
            <th>Received Date</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {textMessages.length > 0 ? (
            orderedTextMessages.map((textMessage) => (
              <tr className="centered-table-data" key={`textMessage${textMessage.SentDate}`}>
                <td className="text-message-table__donorId" data-testid="donorId">
                  {renderGrid(textMessage.DonorId)}
                </td>
                <td className="text-message-table__date" data-testid="sentDate">
                  {formatDate(textMessage.SentDate)}
                </td>
                <td className="text-message-table__date" data-testid="dateReceived">
                  {formatDate(textMessage.ReceivedDate)}
                </td>
                <td data-testid="message">{textMessage.Message}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                No messages found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TextMessagesTable;
