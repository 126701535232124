import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import '../../internationalDonorsTableStyling.scss';
import * as actions from '../../redux/actions';
import { getCountryNames } from '../../../core/helpers/getCountryNames';
import donorTypes from '../../../../core/constants/donorTypes';
import { CurrentReportSelectors } from '../../../core/redux/selectors';
import { SearchRequestSelectors } from '../../../../donorMatchSearchRequests';
import type { ReduxState } from '../../../../rootReducer';
import type { RouterMatch } from '../../../../core/types';
import type { SavedResultSet } from '../../../../donorMatchSearchRequests/types';
import type { Country, CustomCountry, SavedSetCountry } from '../../../types';
import CountryRow from './CountryRow';
import CustomCountryRow from './CustomCountryRow';
import isWhiteSpaceOrEmpty from '../../../../core/helpers/isWhiteSpaceOrEmpty';

type DispatchProps = {
  updateHiddenState: typeof actions.updateHiddenState;
  handlePendingCheckbox: typeof actions.handlePendingCheckbox;
  updateCountryNames: typeof actions.updateSelectedCountryNames;
  updateCustomCountries: typeof actions.setCustomCountries;
  updateSearchedCountries: typeof actions.updateSearchedCountries;
};
type OwnProps = {
  // eslint-disable-next-line react/require-default-props
  match?: RouterMatch;
};
type StateProps = {
  customCountries: CustomCountry[];
  isReportReadOnly: boolean;
  savedDonorSets: Record<string, SavedResultSet>;
  savedSetCountries: SavedSetCountry[];
  searchedCountries: Partial<Country>[];
  selectedCountryNames: (string | undefined)[];
  selectedDonorSetIds: string[];
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  customCountries: CurrentReportSelectors.getCustomCountries(state),
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  savedSetCountries: CurrentReportSelectors.getSavedSetCountries(state),
  searchedCountries: CurrentReportSelectors.getSearchedCountries(state),
  selectedCountryNames: CurrentReportSelectors.getSelectedCountryNames(state),
  savedDonorSets: SearchRequestSelectors.getAllSavedResultSets(state, donorTypes.adult.value),
  selectedDonorSetIds: CurrentReportSelectors.getSelectedResultSetIds(state, donorTypes.adult.value),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  handlePendingCheckbox: bindActionCreators(actions.handlePendingCheckbox, dispatch),
  updateCustomCountries: bindActionCreators(actions.setCustomCountries, dispatch),
  updateSearchedCountries: bindActionCreators(actions.updateSearchedCountries, dispatch),
  updateCountryNames: bindActionCreators(actions.updateSelectedCountryNames, dispatch),
  updateHiddenState: bindActionCreators(actions.updateHiddenState, dispatch),
});

class CountriesTable extends PureComponent<Props> {
  componentDidMount(): void {
    const {
      isReportReadOnly,
      savedDonorSets,
      savedSetCountries,
      searchedCountries,
      selectedDonorSetIds,
      updateCountryNames,
      updateSearchedCountries,
    } = this.props;
    const countryNames = getCountryNames(selectedDonorSetIds, savedDonorSets);
    updateCountryNames(countryNames);
    const searchedCountriesNotInSavedSetCountries = searchedCountries.filter(
      (c) => !savedSetCountries.some((c2) => c2.name === c.name)
    );

    const countriesWithSavedSets = savedSetCountries.map((c: SavedSetCountry) => {
      const existingCountry = searchedCountries.find((c2: Partial<Country>) => c2.name === c.name);
      if (existingCountry && isReportReadOnly) {
        return existingCountry;
      }
      if (existingCountry) {
        return { ...existingCountry, isReported: c.isReported };
      }
      return c;
    });

    const countries = [...countriesWithSavedSets, ...searchedCountriesNotInSavedSetCountries];
    updateSearchedCountries(countries);
  }

  render() {
    const { customCountries, handlePendingCheckbox, isReportReadOnly, searchedCountries, selectedCountryNames } =
      this.props;

    return (
      <div>
        <table className="internationalReportCountries">
          <thead>
            <tr className="border-bottom-solid">
              <th>
                <h2>Countries</h2>
              </th>
              <th>Reporting</th>
              <th>Pending</th>
              <th>Reported</th>
              {!isReportReadOnly && <th>Custom Country</th>}
            </tr>
          </thead>
          <tbody>
            {searchedCountries.map((country: Partial<Country>) => (
              <CountryRow
                country={country as Country}
                key={country.name}
                readOnly={isReportReadOnly}
                isReportingOverride={() => selectedCountryNames.some((c: string | undefined) => c === country.name)}
                onHiddenChange={(hidden: boolean) => this.handleHiddenCountryChange(country as CustomCountry, hidden)}
                onPendingChange={(isPending: boolean) =>
                  handlePendingCheckbox(country.name as string, isPending, this.isNewReport())
                }
              />
            ))}
            {customCountries.map((country: CustomCountry) => (
              <CustomCountryRow
                country={country}
                key={country.name}
                readOnly={isReportReadOnly}
                onCountryChange={this.handleCustomCountryChange}
              />
            ))}
          </tbody>
        </table>
        {Object.values(customCountries).some((country: CustomCountry) =>
          // $FlowExpectedError - flow doesn't like Object.values: property 'name' is mising in mixed
          isWhiteSpaceOrEmpty(country.name)
        ) && (
          <div className="error-message" data-testid="error">
            <span>Cannot leave country name blank</span>
          </div>
        )}
        {!isReportReadOnly && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '30px',
            }}
          >
            <button
              className="btn"
              onClick={this.addCustomCountry}
              style={{ fontSize: '1.5rem', marginRight: '15px' }}
              type="button"
            >
              + Add another country
            </button>
            {customCountries.length >= 1 && (
              <button
                className="btn"
                style={{ fontSize: '1.5rem', marginLeft: '15px' }}
                onClick={this.deleteCustomCountry}
                type="button"
              >
                - Delete custom row
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  deleteCustomCountry = () => {
    const { customCountries, searchedCountries, updateCustomCountries, updateHiddenState } = this.props;
    const searchedCountry = searchedCountries.find((c: Partial<Country>) => c.name === _.last(customCountries)?.name);
    if (searchedCountry && searchedCountry.name) {
      updateHiddenState(searchedCountry.name, false, this.isNewReport());
    }
    updateCustomCountries(customCountries.slice(0, -1));
  };

  addCustomCountry = () => {
    const { customCountries, updateCustomCountries } = this.props;
    updateCustomCountries([
      ...customCountries,
      {
        id: `${customCountries.length + 1}`,
        name: `Custom Country ${customCountries.length + 1}`,
        isPending: false,
        isReported: false,
        isReporting: false,
      },
    ]);
  };

  handleCustomCountryChange = (country: CustomCountry) => {
    const { customCountries, updateCustomCountries } = this.props;

    const indexOfCountry = customCountries.findIndex((x: CustomCountry) => x.id === country.id);

    updateCustomCountries([
      ...customCountries.slice(0, indexOfCountry),
      country,
      ...customCountries.slice(indexOfCountry + 1),
    ]);
  };

  handleHiddenCountryChange = (country: CustomCountry, hidden: boolean) => {
    const { customCountries, updateHiddenState, updateCustomCountries } = this.props;

    updateHiddenState(country.name, hidden, this.isNewReport());

    if (hidden) {
      updateCustomCountries(
        !customCountries.some((c: CustomCountry) => c.name === country.name)
          ? [
              ...customCountries,
              {
                id: `${customCountries.length + 1}`,
                name: country.name,
                isPending: country.isPending,
                isReported: country.isReported,
                isReporting: country.isReporting,
              },
            ]
          : customCountries
      );
    } else {
      updateCustomCountries(customCountries.filter((c: CustomCountry) => c.name !== country.name));
    }
  };

  isNewReport = () => {
    const { match } = this.props;
    return match?.params.reportId === 'new';
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CountriesTable);
