import React from 'react';
import { ExternalInvestigationTextMessage, SampleInformation } from '../../../../../externalInvestigations/types';
import SampleInformationTabs from './SampleInformation/SampleInformationTabs';

type Props = {
  sampleInformations: SampleInformation[];
  textMessages: ExternalInvestigationTextMessage[];
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
};

const SampleInformationPopUp = ({ sampleInformations, onCancel, textMessages }: Props) => (
  <>
    <h1>Sample Information</h1>
    <SampleInformationTabs sampleInformations={sampleInformations} textMessages={textMessages} />
    <div className="btn-actions">
      <button className="btn btn--secondary btn--inline" type="button" onClick={onCancel}>
        Close
      </button>
    </div>
  </>
);

export default SampleInformationPopUp;
