import React from 'react';
import { useSelector } from 'react-redux';
import { HlaPopUpWrapper } from '@an/nova-frontend-hla-popup';
import _ from 'lodash';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import { formatRequestContext } from '../../../../../core/helpers/markupHelpers';
import { Antigen } from '../../../../../core/types';
import Selectors from '../../../redux/selectors';
import { ReduxState } from '../../../../../rootReducer';
import { LoadingMessage } from '../../../../../core';
import { Hla } from '../../../types';
import { colors } from '../../../../../style';
import { formatDate } from '../../../../../core/helpers/dateHelpers';
import './hlaSummaryPopup.scss';
import {
  ExternalInvestigationReportDetails,
  ExternalInvestigationReports,
  ExternalInvestigationReportStatus,
} from '../../../../../externalInvestigations/types';
import { Button } from '../../../../../core/components/Button';

export type HlaSummaryData = {
  donorId: string;
  patientId: string;
  investigationId: number;
  grid: string;
};

type Props = {
  hlaSummaryData: HlaSummaryData;
  reports: ExternalInvestigationReports;
  generateHLAReport: () => void;
  downloadReport: (filename: string) => void;
  onClose: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
};

type LocusData = Antigen | [Antigen, Antigen];
type CombinedLocusData = [LocusData?, LocusData?];

type SingleDonorHla = {
  [locus: string]: LocusData;
};

type CombinedDonorHla = {
  [locus: string]: CombinedLocusData;
};

export const HlaSummaryPopup = (props: Props) => {
  const { hlaSummaryData, reports, onClose } = props;
  const getRowClass = (rowIndex: number) =>
    isOddNumber(rowIndex) ? 'hla-summary-popup-odd' : 'hla-summary-popup-even';

  const isHlaSummaryLoading = useSelector((state: ReduxState) => Selectors.isHlaSummaryLoading(state));
  const data = useSelector((state: ReduxState) => Selectors.getHlaSummary(state));

  if (isHlaSummaryLoading) {
    return <LoadingMessage isLoading={isHlaSummaryLoading} />;
  }

  if (data === undefined) {
    return <div>No data</div>;
  }

  const { bloodGroup, dateReceived, updatedHla, originalHla, rhesus, remark, registryName } = data;

  const mapHlaDictToFullHla = (hlaDict: Hla): SingleDonorHla => {
    let locus: keyof Hla;
    const hlaArray: Antigen[] = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (locus in hlaDict) {
      hlaArray.push(hlaDict[locus]);
    }
    const tempData = _.groupBy(hlaArray, (antigen) => antigen.locus);
    const fullHla: SingleDonorHla = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const locusName in tempData) {
      const [first, second] = tempData[locusName];
      if (first.id !== undefined && second.id !== undefined) {
        if (first.id === second.id) {
          fullHla[locusName] = first;
        } else {
          fullHla[locusName] = [first, second];
        }
      } else if (first.id !== undefined || second.id !== undefined) {
        fullHla[locusName] = first.id === undefined ? second : first;
      }
    }
    return fullHla;
  };

  const processHla = (updatedHlaDict: Hla, originalHlaDict: Hla) => {
    const updatedFullHla = mapHlaDictToFullHla(updatedHlaDict);
    const originalFullHla = mapHlaDictToFullHla(originalHlaDict);

    const commonKeys = new Set<string>([...Object.keys(updatedFullHla), ...Object.keys(originalFullHla)]);
    const reportFullDonorHla: CombinedDonorHla = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key of commonKeys) {
      const updatedLocus = updatedFullHla[key];
      const originalLocus = originalFullHla[key];
      reportFullDonorHla[key.toUpperCase()] = [originalLocus, updatedLocus];
    }

    return reportFullDonorHla;
  };

  const reportFullDonorHla = processHla(updatedHla, originalHla);

  const generateCell = (locusData: LocusData | undefined): JSX.Element => {
    if (locusData === undefined) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    if ('id' in locusData) {
      return <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locusData} />;
    }
    return (
      <>
        <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locusData[0]} />
        <HlaPopUpWrapper highlightColour={colors.ANGreen} hlaData={locusData[1]} />
      </>
    );
  };

  const generateHlaDataRow = (reportLocusData: CombinedLocusData, locusName: string, rowIndex: number): JSX.Element => {
    const rowClass = getRowClass(rowIndex);
    return (
      <React.Fragment key={locusName}>
        <div className={`hla-summary-popup-table-header-cell ${rowClass}`}>
          <div>{locusName}</div>
        </div>

        <div className={`hla-summary-popup-table-cell ${rowClass}`}>{generateCell(reportLocusData[0])}</div>
        <div className={`hla-summary-popup-table-cell ${rowClass}`}>{generateCell(reportLocusData[1])}</div>
      </React.Fragment>
    );
  };

  const generateFullReportHlaLayout = (hlaForReportGeneration: CombinedDonorHla): JSX.Element => {
    let rowIndex = 1;
    const tableRows = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const locusName in hlaForReportGeneration) {
      const locus = hlaForReportGeneration[locusName];
      const row = generateHlaDataRow(locus, locusName, rowIndex);
      tableRows.push(row);
      rowIndex += 1;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{tableRows}</>;
  };

  const donor = {
    id: hlaSummaryData.donorId,
    grid: hlaSummaryData.grid,
    originatingRegistry: {
      id: '',
      name: registryName,
    },
  };

  const getReportButton = (text: string, reportStatus: ExternalInvestigationReportStatus, action: () => void) => {
    if (reportStatus === ExternalInvestigationReportStatus.Generating) {
      return <div className="disabled-action btn--test-result">Processing</div>;
    }

    if (reportStatus === ExternalInvestigationReportStatus.Downloading) {
      return <div className="disabled-action btn--test-result">Downloading</div>;
    }

    return (
      <Button
        text={text}
        onClick={() => {
          action();
        }}
        buttonClass="btn btn--inline"
      />
    );
  };
  const getHLAReportButton = (
    hlaReportDetails: ExternalInvestigationReportDetails,
    generateHLAReport: () => void,
    downloadReport: (filename: string) => void
  ) =>
    getReportButton(
      'Download HLA Report',
      hlaReportDetails.reportStatus,
      hlaReportDetails.reportStatus === ExternalInvestigationReportStatus.ReadyToDownload
        ? () => downloadReport(hlaReportDetails.reportFilename as string)
        : generateHLAReport
    );

  const { generateHLAReport, downloadReport } = props;

  return (
    <>
      <h2 className="hla-summary-popup-header border-bottom-solid">HLA summary</h2>
      <div>{formatRequestContext(hlaSummaryData.patientId, donor.originatingRegistry.name, donor.id, donor.grid)}</div>
      <div className="hla-summary-popup-table w-25">
        <div className="hla-summary-popup-table-header-cell">Received date</div>
        <div className="hla-summary-popup-table-cell">
          <span>{formatDate(dateReceived as string)}</span>
        </div>
        <div className="hla-summary-popup-table-header-cell">Blood type</div>
        <div className="hla-summary-popup-table-cell">
          <span>
            {bloodGroup}
            {rhesus}
          </span>
        </div>
      </div>
      <div className="hla-summary-popup-table w-33">
        <div key="locus" className="hla-summary-popup-table-header-cell">
          Locus
        </div>
        <div key="original" className="hla-summary-popup-table-header-cell">
          Original HLA
        </div>
        <div key="current" className="hla-summary-popup-table-header-cell">
          Current HLA
        </div>
        {generateFullReportHlaLayout(reportFullDonorHla)}
      </div>
      <div className="hla-summary-popup-table w-33">
        <div className="hla-summary-popup-remark-wrapper">
          <div className="hla-summary-popup-remark-header">Remarks</div>
          <div className="hla-summary-popup-remark-field">{remark || 'None'}</div>
        </div>
      </div>
      <div className="hla-summary-popup-buttons-wrapper">
        <div className="hla-summary-popup-button-wrapper btn-download">
          {getHLAReportButton(reports.hlaReportDetails, generateHLAReport, downloadReport)}
        </div>
        <div className="hla-summary-popup-button-wrapper btn-close">
          <button type="button" className="btn btn--secondary btn--inline" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};
