import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { styles } from '../../../../style/index';
import { SavedReportsSelectors } from '../../../core/redux/selectors';
import ReportStatusDisplay from '../../../currentReport/components/ReportStatusDisplay';
import { ReportStatuses } from '../../../core/constants';
import reportTypes from '../../../../core/constants/reportTypes';
import DeleteReportButton from '../DeleteReportButton';

import type { ApiSavedReport } from '../../../types/api';
import type { ReduxState } from '../../../../rootReducer';
import { ApiSearchType, convertSearchTypeFromApiToNumeric } from '../../../../core/constants/searchTypes';

type OwnProps = {
  report: ApiSavedReport;
  shouldShowDeleteDraftButton: boolean;
};
type StateProps = {
  totalSelectedDonorCount: {
    AB: number;
    ABDR: number;
  };
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  totalSelectedDonorCount: SavedReportsSelectors.getReportsTotalNumberOfSavedDonors(state, ownProps.report.Id),
});

export class ReportSummaryCard extends PureComponent<Props> {
  render() {
    const { report, shouldShowDeleteDraftButton, totalSelectedDonorCount } = this.props;
    const reportType = report.IsInternational ? reportTypes.international : reportTypes.internal;
    return (
      <div className="summaryCard">
        <Link to={`/patient/${report.PatientId}/reports/${report.Id}?reportType=${reportType}`} style={styles.cardLink}>
          <header className="cardHeader">
            <div>
              Patient {report.PatientId} - <ReportStatusDisplay status={report.ReportStatus} />
            </div>
          </header>
        </Link>
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0px 15px 0px' }}>
          <table className="summaryCardTable">
            <tr>
              <td>
                <strong>Exact Matches: </strong>
              </td>
              <td className="numberCell">{report.SearchSummary.ABDR.Exact}</td>
              <td>
                <strong>Mismatches: </strong>
              </td>
              <td className="numberCell">{report.SearchSummary.ABDR.Mismatch}</td>
            </tr>
            <tr>
              <td>
                <strong>Potential Matches: </strong>
              </td>
              <td className="numberCell">{report.SearchSummary.ABDR.Potential}</td>
              <td>
                <strong>Selected Donors: </strong>
              </td>
              <td className="numberCell">{totalSelectedDonorCount.ABDR}</td>
            </tr>
            <br />
          </table>
          <br />
          <table className="summaryCardTable">
            <tr>
              <td>
                <strong>Received: </strong>
              </td>
              <td>{report.DateSearchReceived ? moment(report.DateSearchReceived).format('DD/MM/YYYY HH:mm') : '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>Created: </strong>
              </td>
              <td>
                {moment(report.DateGenerated).format('DD/MM/YYYY')} {report.Author}
              </td>
            </tr>
            {this.renderAuthoriserAndSentDetails()}
            {this.renderSearchTypes()}
            {report.ReportStatus === ReportStatuses.Draft && shouldShowDeleteDraftButton && (
              <DeleteReportButton report={report} />
            )}
          </table>
        </div>
      </div>
    );
  }

  renderAuthoriserAndSentDetails = () => {
    const { report } = this.props;
    const { ReportStatus, DateAuthorised, Authoriser, DateSent } = report;

    if (ReportStatus === ReportStatuses.Authorised || ReportStatus === ReportStatuses.Sent) {
      return (
        <>
          <tr>
            <td>
              <strong>Authorised: </strong>
            </td>
            <td>
              {DateAuthorised ? moment(DateAuthorised).format('DD/MM/YYYY') : 'N/A'} {Authoriser}
            </td>
          </tr>
          {ReportStatus === ReportStatuses.Sent ? (
            <tr>
              <td>
                <strong>Sent: </strong>
              </td>
              <td>{moment(DateSent).format('DD/MM/YYYY')}</td>
            </tr>
          ) : null}
        </>
      );
    }
    return null;
  };

  renderSearchTypes = () => {
    const { report } = this.props;
    const uniqueTypes = [...new Set(report.Types)];
    const convertedUniqueTypes = uniqueTypes.map((type) => convertSearchTypeFromApiToNumeric(type as ApiSearchType));
    const typesString = convertedUniqueTypes.slice().reverse().join(', ');
    if (uniqueTypes.length === 0) {
      return null;
    }
    return (
      <tr>
        <td className="searchTypesCell">
          <strong>Search Types:&nbsp;</strong>
        </td>
        <td>{typesString}</td>
      </tr>
    );
  };
}

const connector = connect(mapStateToProps);

export default connector(ReportSummaryCard);
